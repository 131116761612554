<template>
    <div>
        <other-header></other-header>
        <div class="office-detail">
        
            <detail-header last-name="返回" :title="info.PostName"/>
            <div class="info flex-col flex-j-start flex-a-start">
                <div class="flex-row flex-j-between flex-a-start">
                    <div class="office flex-col flex-j-start flex-a-start">
                        <div class="comName">{{ info.CompanyName }}
                        
                        </div>
                        <span class="name info-head">{{ info.PostName }}</span>
                        <span v-if="info.SalaryMin <= 0 || info.SalaryMax <= 0" class="price">薪资面议</span>
                        <span v-else class="price">月工资:{{ info.SalaryMin }}~{{ info.SalaryMax }}</span>
                        <span>招聘人数:{{ info.NeedNum }}名</span>
                        
<!--                        <div class="conditions">-->
<!--                            <span>岗位要求:</span>-->
<!--                            <div v-for="(requirement, index) in info.RequirementsList||[]" :key="index" class="condition">-->
<!--                                <div class="icon"></div>-->
<!--                                <span>{{ requirement }}</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    -->
<!--                        <div class="welfares">-->
<!--                            <span>公司福利:</span>-->
<!--                            <div v-for="(welfare, index) in info.WelfareList||[]" :key="index" class="welfare">{{ welfare }}-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                    <!-- <un-image class="logo" src="/"></un-image> -->
            
                </div>
            
                <div class="user flex-row flex-j-start flex-a-start">
                    <!-- <un-image class="user-icon" src="/"></un-image> -->
                    <div class="flex-col flex-j-between flex-a-start">
                        <span>联系人：{{ info.LinkMan }}</span>
                        <span class="flex-row flex-a-center" style="height: 1.0rem">联系电话：
                        {{ info.LinkPhone }}
                        <a class="iconfont icon-bohao"></a></span>
                        <span>公司地址：{{ info.CompanyAdd }}</span>
                    </div>
                </div>
                <div class="hot-msg flex-row flex-j-between flex-a-center" style="margin-top: 20px">
                    <div class="flex-col flex-j-around flex-a-start">
                        <span>温馨提示：</span>
                        <span class="msg">求职过程中请勿缴纳费用，注意验证对方资质，谨防诈骗！若信息不实请举报。</span>
                    </div>
                    <img class="icon" src="@/assets/icons/jing.png"/>
                </div>
                <br>
                <div v-if="hideTime!=1" class="flex-row flex-j-end flex-a-start">
                    <span class="time">发布日期:{{ info.timeText }}</span>
                    <div style="width: 0.4rem"></div>
<!--                    <span class="time">更新日期:{{ info.upTimeText }}</span>-->
                </div>
            </div>
<!--            <div class="info scroll">-->
<!--                <div class="info-head">详情</div>-->
<!--                <div v-html="info.Details"></div>-->
<!--                <div class="address">-->
<!--                    <span>公司地址：</span>-->
<!--                    <span>{{ info.CompanyAdd }}</span>-->
<!--                </div>-->
<!--        -->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
// office-detail
import DetailHeader from "@/components/detail-header";
import OtherHeader from "@/components/other-header";

export default {
    name: "office-detail",
    components: {OtherHeader, DetailHeader},
    data() {
        return {
            info: {}
        }
    },
    computed: {
        id() {
            return this.$route.query.id;
        },
        hideTime() {
            return this.$route.query.hideTime;
        }
    },
    activated() {
        this.loadData();
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            // path /BasePostInfo/GetPostInfo
            const params = {
                id: this.id
            };
            const res = await $app.httpGet({
                path: `/BasePostInfo/GetPostInfo`,
                params: params,
            });
            if (res.ok) {
                const data = res.data;
                data.timeText = $app.dateFormatChinese(data.BaseCreateTime);
                data.upTimeText = $app.dateFormatChinese(data.BaseModifyTime);
                let Requirements = data.Requirements || "";
                let Welfare = data.Welfare || "";
                // 汉字逗号改为英文逗号
                Requirements = Requirements.replace(/，/g, ',').replace(/、/g, ",");
                data.RequirementsList = Requirements.split(",");
                
                Welfare = Welfare.replace(/，/g, ",").replace(/、/g, ",");
                data.WelfareList = Welfare.split(",");
                
                if (data.RequirementsList[0] == "") {
                    data.RequirementsList = [];
                }
                if (data.WelfareList[0] == "") {
                    data.WelfareList = [];
                }
                if (data.LinkPhone) {
                    data.LinkPhone += "";
                    try {
                        data.previtePhone = data.LinkPhone.substr(0, 3) + "****" + data.LinkPhone.substr(7, 11)
                    } catch (e) {
                        data.previtePhone = "***********"
                    }
                }
                
                this.info = data;
            }
        },
    }
}
</script>

<style scoped lang="less">
.office-detail {
    width: calc(18rem - 0.4rem);
    margin: 0.4rem auto;
}

.info {
    padding: .40rem;
    margin-bottom: .20rem;
    box-sizing: border-box;
    //box-shadow: 0 .04rem .10rem 0 rgba(0, 0, 0, 0.3);
    border-radius: .08rem;
    background-color: #fff;
    font-size: .36rem;
    color: #333;
}

.info .flex-row {
    width: 100%;
}

.info .name {
    font-size: .46rem;
    font-weight: 600;
    color: #333;
    line-height: .40rem;
}

.info .price {
    font-size: .36rem;
    color: #f1676d;
}

.conditions {
    padding: .10rem 0;
    font-size: .36rem;
    color: #333;
}

.condition {
    height: .40rem;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: .32rem;
    margin-right: .30rem;
    color: orange;

}

.condition .icon {
    width: .06rem;
    height: .20rem;
    background-color: orange;
    margin-right: .04rem;
    border-radius: .10rem;
}

.welfare {
    font-size: .26rem;
    color: #4eabe8;
    border: 1px solid #4eabe8;
    padding: .04rem .10rem;
    display: inline-block;
    margin-right: .20rem;
    border-radius: .06rem;
    margin-bottom: .10rem;
}

.info .logo {
    width: 2.00rem;
    height: 2.00rem;
    border-radius: .12rem;
    overflow: hidden;
    color: rgb(78, 171, 232);
    text-align: right;
}

.info .user {
    font-size: .36rem;
    color: #333;
}

.info .user .user-icon {
    width: 1.20rem;
    height: 1.20rem;
    border-radius: 2.00rem;
    margin-right: .20rem;
    display: none;
}

.address {
    margin-top: .40rem;
}

.hot-msg {
    //position: fixed;
    //left: 0;
    //right: 0;
    //bottom: .80rem;
    //z-index: 99;
    //width: 6.0rem !important;
    background-color: rgb(255, 255, 239);
    font-size: .36rem;
    /* margin-top: 40rem; */
    /* margin-bottom: 40rem; */
    padding: .20rem;
    box-sizing: border-box;
}

.hot-msg .icon {
    width: .80rem;
    height: .80rem;
    margin-left: .20rem;
}

.hot-msg .msg {
    font-size: .32rem;
    color: red;
}

.info-head {
    font-size: .46rem;
    font-weight: 600;
    color: #333;
    border-left: .08rem solid #f1676d;
    margin: .10rem 0 .30rem 0rem;
    padding-left: .20rem;
}

.time {
    font-size: .36rem;
    color: #999999;
}

.comName {
    text-align: left;
    color: #4eabe8;
    font-size: .36rem;
    font-weight: 600;
    //border-left: .08rem solid #4eabe8;
    margin: .10rem 0 .30rem 0rem;
    padding-left: .20rem;
    /* color: #4eabe8; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.scroll {
    text-align: left;
}

.office {
    width: 100%;
}

.icon-bohao {
    font-size: 0.4rem;
    color: #39a9ed;
    cursor: pointer;
    
    //&:hover {
    //    font-size: 0.6rem;
    //    color: #39a9ed;
    //}
}
</style>
